var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.state.darkMode },
    },
    [
      _c("CourierSidebar"),
      _c(
        "CWrapper",
        [
          _c("TheHeader"),
          _c("div", { staticClass: "c-body" }, [
            _c(
              "main",
              { staticClass: "c-main" },
              [
                _c(
                  "CContainer",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [_c("router-view", { key: _vm.$route.path })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("TheFooter"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }